<template>
  <div class="readme-article">
    <h1 id="报表">六、报表</h1>
    <h2 id="线索统计">1.线索统计</h2>
    <p>查看线索统计记录。</p>
    <p><img src="@/assets/img/call/6-1.png" /></p>
    <h2 id="按日期统计新增线索">2.按日期统计新增线索</h2>
    <p>查看按日期统计的新增线索记录。</p>
    <p><img src="@/assets/img/call/6-2.png" /></p>
    <h2 id="线索来源统计">3.线索来源统计</h2>
    <p>查看线索来源统计记录。</p>
    <p><img src="@/assets/img/call/6-3.png" /></p>
    <h2 id="登记方式统计">4.登记方式统计</h2>
    <p>查看登记方式统计记录。</p>
    <p><img src="@/assets/img/call/6-4.png" /></p>
    <h2 id="按意向状态统计">6.按意向状态统计</h2>
    <p>查看按意向状态统计记录。</p>
    <p><img src="@/assets/img/call/6-5.png" /></p>
    <h2 id="流失原因统计">6.流失原因统计</h2>
    <p>查看流失原因统计记录。</p>
    <p><img src="@/assets/img/call/6-6.png" /></p>
    <h2 id="无效原因统计">7.无效原因统计</h2>
    <p>查看无效原因统计记录。</p>
    <p><img src="@/assets/img/call/6-7.png" /></p>
    <h2 id="客服信息统计">8.客服信息统计</h2>
    <p>查看客服信息统计记录。</p>
    <!-- <p><img src="@/assets/img/call/6-8.png" /></p> -->
  </div>
</template>

<script>
export default {
  name: "call6-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>